import React, { useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Alert from '../../Registration/Alert';
import RegistrationForm from '../../Registration/RegistrationForm';
import Input from '../../Registration/RegistrationForm/Input';
import { EMAIL_REGEX } from '../../../../utils/constants';
import { changePrimaryEmail } from '../../../../state/actions/identityActions';
import ErrorAlert from '../../Registration/RegistrationStepPageBody/ErrorAlert';
import { ButtonLinkRedStyles, ButtonLinkStyles } from '../../../ButtonLink';
import { login } from '../../../../utils/auth';

const SuccessAlert = styled.div`
  div {
    margin-bottom: 8px;
  }

  margin-bottom: 32px;
`;

const Button = styled.button`
  ${ButtonLinkStyles}
  ${ButtonLinkRedStyles}

  min-width: 312px;
  font-weight: ${prop('theme.fontWeights.semiBold')};
`;

const propTypes = { data: PropTypes.object };

const defaultProps = { data: {} };

function PrimaryEmailChangeForm({ data }) {
  const {
    text_block,
    alert_text,
    success_image,
    success_message,
    success_button_text
  } = data.primary;

  const defaultValues = { primaryEmail: '', primaryEmailConfirm: '' };
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues
  });
  const primaryEmailValue = watch('primaryEmail', '');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSuccessfullyChanged, setHasSuccessfullyChanged] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setIsSubmitting(true);
    dispatch(
      changePrimaryEmail(
        data.primaryEmail,
        () => {
          setHasSuccessfullyChanged(true);
        },
        () => {
          setIsSubmitting(false);
        }
      )
    );
  };

  const successButtonOnClick = () => {
    login('callbackRedirectPath=/community-portal');
  };

  return (
    <>
      {hasSuccessfullyChanged ? (
        <SuccessAlert>
          <Alert
            data={{
              primary: { text_content: success_message, image: success_image }
            }}
            template='no-side-padding'
          />
          <Button onClick={successButtonOnClick}>{success_button_text}</Button>
        </SuccessAlert>
      ) : (
        <>
          <PrismicRichText field={text_block.richText} />
          <ErrorAlert disableBottomMargin />
          <Alert
            data={{
              primary: {
                text_content: alert_text,
                border_color: 'redPrimary'
              }
            }}
            template='no-side-padding'
          />
          <RegistrationForm
            handleSubmit={handleSubmit(onSubmit)}
            nextButtonText='Save Changes'
            removePreviousButton
            customRequiredMessage='Required'
            isValid={isValid}
            isSubmitting={isSubmitting}
          >
            <Input
              type='text'
              name='primaryEmail'
              labelText='New Primary Email*'
              defaultValue={defaultValues.primaryEmail}
              error={errors.primaryEmail}
              {...register('primaryEmail', {
                required: 'Primary Email is required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Must be a valid email'
                }
              })}
            />
            <Input
              type='text'
              name='primaryEmailConfirm'
              labelText='Confirm New Primary Email*'
              defaultValue={defaultValues.primaryEmailConfirm}
              error={errors.primaryEmailConfirm}
              {...register('primaryEmailConfirm', {
                required: 'Primary Email Confirmation is required',
                pattern: {
                  value: EMAIL_REGEX,
                  message: 'Must be a valid email'
                },
                validate: (value) =>
                  value === primaryEmailValue || 'Emails must match'
              })}
            />
          </RegistrationForm>
        </>
      )}
    </>
  );
}

PrimaryEmailChangeForm.propTypes = propTypes;
PrimaryEmailChangeForm.defaultProps = defaultProps;

export default PrimaryEmailChangeForm;
